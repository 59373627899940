import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'IconArrow',
  props: {
    dir: {}
  },
  setup(__props: any) {


const { t } = useI18n()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("i", {
    class: _normalizeClass(["arrow", _ctx.dir])
  }, null, 2))
}
}

})